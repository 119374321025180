
// import React, { useEffect, useState } from "react";
// import Sidebar from "../Chat/Sidebar";
// import './Credits.css';
// import { Col, Row, Table, Card, Dropdown, Pagination } from "react-bootstrap";
// import { GrMoney } from "react-icons/gr";
// import { IoIosNotifications, IoIosSearch } from "react-icons/io";
// import { MdAccountCircle } from "react-icons/md";
// import { useNavigate } from "react-router-dom";

// const Credits = () => {
//   const [superAdminCredits, setSuperAdminCredits] = useState('1000');
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [amountToGive, setAmountToGive] = useState('');
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [searchInput, setSearchInput] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 2; // Number of users per page
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await fetch('https://demochatapi.emedha.in/api/departuser');
//         const data = await response.json();
//         setUsers(data);
//         setFilteredUsers(data); // Initialize filtered users
//       } catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   console.log(filteredUsers);

//   // Search filter
//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearchInput(value);

//     const filtered = users.filter(user => {
//       const usernameMatch = user.username.toLowerCase().includes(value.toLowerCase());
//       const creditsMatch = user.credits && user.credits.toString().includes(value);
//       return usernameMatch || creditsMatch;
//     });

//     setFilteredUsers(filtered);
//     setCurrentPage(1); // Reset to first page on search
//   };

//   const handleGiveCredits = async () => {
//     if (!selectedUser) {
//       alert('Please select an admin.');
//       return;
//     }

//     const credits = parseInt(amountToGive);

//     if (isNaN(credits) || credits <= 0 || credits > superAdminCredits) {
//       alert('Invalid amount. Please enter a valid credit amount.');
//       return;
//     }

//     try {
//       const response = await fetch(`https://demochatapi.emedha.in/api/transactions/${selectedUser.id}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ username: selectedUser.username, credits }),
//       });

//       if (!response.ok) {
//         const error = await response.json();
//         alert(error.message || 'Failed to give credits. Please try again.');
//         return;
//       }

//       const result = await response.json();
//       setSuperAdminCredits(superAdminCredits - credits);
//       setAmountToGive('');
//       setSelectedUser(null);
//       alert(result.message || 'Credits given successfully!');
//       window.location.reload(); 
//     } catch (error) {
//       console.error('Error giving credits:', error);
//       alert('Server error. Please try again later.');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('userData');
//     localStorage.removeItem('token');
//     localStorage.removeItem('loginEmail');
//     localStorage.removeItem('rememberMe');
//     sessionStorage.removeItem('username');
//     localStorage.removeItem('userId');
//     navigate('/');
//   };

//   // Pagination logic
//   const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
//   const currentUsers = filteredUsers.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPaginationItems = () => {
//     const items = [];

//     // Previous button
//     items.push(
//       <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//         &lt;
//       </Pagination.Item>
//     );

//     // Page numbers
//     for (let i = 1; i <= totalPages; i++) {
//       items.push(
//         <Pagination.Item
//           key={i}
//           active={i === currentPage}
//           onClick={() => handlePageChange(i)}
//         >
//           {i}
//         </Pagination.Item>
//       );
//     }

//     // Next button
//     items.push(
//       <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//         &gt;
//       </Pagination.Item>
//     );

//     return items;
//   };

//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <div className="container-fluid">
//           <div className="row justify-content-between align-items-center">
//             <div className="col-auto">
//               <h3 className="mt-2"><strong>Manage Credits</strong></h3>
//             </div>
//             <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//               <IoIosNotifications size={30} />
//               <MdAccountCircle size={30} onClick={handleLogout} style={{ cursor: 'pointer' }} />
//             </div>
//           </div>
//         </div>

//         <hr />

//         <div>
//           <Row>
//             <div className="search-bar  m-3">
//               <input
//                 type="text"
//                 value={searchInput}
//                 onChange={handleSearch}
//                 placeholder="Search"
//                 className="search-input"
//               />
//               <IoIosSearch className="search-icon" />
//             </div>
//             <Col className="text-end">
//               <h2>Credits <GrMoney style={{ fontSize: '20px' }} />: {superAdminCredits}</h2>
//             </Col>
//           </Row>

//           {/* Search Bar */}
//           {/* <div>
//             <input
//               type="text"
//               value={searchInput}
//               onChange={handleSearch}
//               placeholder="Search "
//             />
//           </div> */}



//           <div className="p-2">

//             <h4>Select Admins to add Credits</h4>

//             <Row>
//               <Col className="text-start">

//                 <Dropdown onSelect={(eventKey) => setSelectedUser(users.find(user => user.id.toString() === eventKey))}>
//                   <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: 'rgb(125, 133, 195)' }}>
//                     {selectedUser ? selectedUser.username : "Select an Admin"}
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     {filteredUsers.map(user => ( // Use filteredUsers here
//                       <Dropdown.Item key={user.id} eventKey={user.id}>{user.username}</Dropdown.Item>
//                     ))}
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </Col>
//               </Row>
//               <Row>
//               <Col>
//               <input
//                   type="number"
//                   value={amountToGive}
//                   onChange={(e) => setAmountToGive(e.target.value)}
//                   placeholder="Enter Credits "
//                 />        
//                 <button style={{ margin:'10px',backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleGiveCredits}>Submit</button>
//               </Col>
//             </Row>
//           </div>

//           <h3>Admin Credits Details</h3>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>Admin Username</th>
//                 <th>Total Credits</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentUsers.map(user => (
//                 <tr key={user.id}>
//                   <td>{user.username}</td>
//                   <td>{user.credits || 0}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           {/* Pagination Controls */}
//           <Pagination className="justify-content-center text-end position-fixed bottom-0 start-50  mt-4">
//             {renderPaginationItems()}
//           </Pagination>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Credits;


import React, { useEffect, useState } from "react";
import Sidebar from "../Chat/Sidebar";
import './Credits.css';
import { Col, Row, Table, Card, Dropdown, Pagination } from "react-bootstrap";
import { GrMoney } from "react-icons/gr";
import { IoIosNotifications, IoIosSearch } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";

const Credits = () => {
  const [superAdminCredits, setSuperAdminCredits] = useState('1000');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [amountToGive, setAmountToGive] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of users per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://demochatapi.emedha.in/api/departuser');
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data); // Initialize filtered users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Search filter
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    const filtered = users.filter(user => {
      const usernameMatch = user.username.toLowerCase().includes(value.toLowerCase());
      const creditsMatch = user.credits && user.credits.toString().includes(value);
      return usernameMatch || creditsMatch;
    });

    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleGiveCredits = async () => {
    if (!selectedUser) {
      alert('Please select an admin.');
      return;
    }

    const credits = parseInt(amountToGive);

    if (isNaN(credits) || credits <= 0 || credits > superAdminCredits) {
      alert('Invalid amount. Please enter a valid credit amount.');
      return;
    }

    try {
      const response = await fetch(`https://demochatapi.emedha.in/api/transactions/${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUser.username, credits }),
      });

      if (!response.ok) {
        const error = await response.json();
        alert(error.message || 'Failed to give credits. Please try again.');
        return;
      }

      const result = await response.json();
      setSuperAdminCredits(superAdminCredits - credits);
      setAmountToGive('');
      setSelectedUser(null);
      alert(result.message || 'Credits given successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error giving credits:', error);
      alert('Server error. Please try again later.');
    }
  };



  // Pagination logic
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const items = [];

    // Previous button
    items.push(
      <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
        &lt;
      </Pagination.Item>
    );

    // Define range of pages to display
    const pageRange = 1; // Number of pages to show around the current page
    const startPage = Math.max(1, currentPage - pageRange);
    const endPage = Math.min(totalPages, currentPage + pageRange);

    // Page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Next button
    items.push(
      <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
        &gt;
      </Pagination.Item>
    );

    return items;
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleItemClick = (action) => {
    console.log(action);
    setDropdownVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('username');
    localStorage.removeItem('userId');
    navigate('/');
  };

  return (
    <>
      <Sidebar />
      <div id='main'>


        <div className="container-fluid bg-light p-3 shadow">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h3 className="mt-2"><strong>Manage Credits</strong></h3>
            </div>
            <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
              <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
              <div style={{ position: 'relative' }}>
                <MdAccountCircle
                  size={30}
                  onClick={toggleDropdown}
                  style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                />
                {dropdownVisible && (
                  <div className="dropdown-menu show" style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                  }}>
                    <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                      <FaUserEdit style={{ marginRight: '8px' }} />
                      Edit Profile
                    </div>
                    <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                      <FaLock style={{ marginRight: '8px' }} />
                      Change Password
                    </div>
                    <div onClick={handleLogout} className="dropdown-item">
                      <FaSignOutAlt style={{ marginRight: '8px' }} />
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>




        <hr />

        <div>
          <Row>
            <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={searchInput}
                onChange={handleSearch}
                placeholder="Search "
                className="form-control"
                style={{
                  paddingLeft: '40px', // Space for the icon
                  paddingRight: '40px', // Space for any right-side elements
                  borderRadius: '25px', // Rounded corners
                  border: '1px solid #ced4da', // Border color

                  transition: 'border-color 0.3s',
                }}
                onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
                onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
              />
              <IoIosSearch

                style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
                size={20} // Adjust size as needed
              />
            </div>

            <Col className="text-end">
              <h2 style={{ color: 'black' }}>Credits <GrMoney style={{ fontSize: '20px', color: 'green' }} />: {superAdminCredits}</h2>
            </Col>
          </Row>


          {/* <h4>Select Admins to add Credits</h4> */}
          <div style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
              <Dropdown onSelect={(eventKey) => setSelectedUser(users.find(user => user.id.toString() === eventKey))}>
                <Dropdown.Toggle id="dropdown-basic" style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }}>
                  {selectedUser ? selectedUser.username : "Select an Admin"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {filteredUsers.map(user => (
                    <Dropdown.Item key={user.id} eventKey={user.id}>{user.username}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ height: '50px', padding: '5px', borderRadius: '10px', marginRight: '10px' }}>
              <input
                type="number"
                value={amountToGive}
                onChange={(e) => setAmountToGive(e.target.value)}
                placeholder="Enter Credits "
              />
            </div>


            <div>
              <button style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleGiveCredits}>Submit</button>
            </div>
          </div>



          <h3>Admin Credits Details</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Admin Username</th>
                <th>Total Credits</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.username}</td>
                  <td>{user.credits || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <Pagination className='d-flex flex-row justify-content-end '>
            {renderPaginationItems()}
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default Credits;
