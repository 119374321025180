// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import { IoMdContact } from 'react-icons/io';
// import { FaSmile } from 'react-icons/fa';
// import { AiOutlineSend, AiOutlinePaperClip } from 'react-icons/ai';
// import EmojiPicker from 'emoji-picker-react';
// import './Chat.css'; // Your styles
// import Sidebar from './Sidebar';

// const GroupChat = () => {
//     const { groupId } = useParams(); // Get group ID from URL
//     const [group, setGroup] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false);

//     useEffect(() => {
//         // Fetch group details based on groupId
//         const fetchGroup = () => {
//             // Replace this with your actual data fetching logic
//             const fetchedGroup = {
//                 id: groupId,
//                 name: `Group ${groupId}`,
//                 members: [{ id: 1, phoneNumber: '+1234567890' }, { id: 2, phoneNumber: '+0987654321' }],
//             };
//             setGroup(fetchedGroup);
//         };
//         fetchGroup();
//     }, [groupId]);

//     const handleSendMessage = () => {
//         if (newMessage.trim()) {
//             const messageObject = {
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//                 sender: 'me',
//             };
//             setMessages([...messages, messageObject]);
//             setNewMessage('');
//         }
//     };

//     const handleEmojiSelect = (emojiData) => {
//         setNewMessage(newMessage + emojiData.emoji);
//         setShowEmojiPicker(false);
//     };

//     if (!group) {
//         return <div>Loading...</div>; // Show loading while fetching
//     }

//     return (
//         <>
//         <Sidebar/>
//         <div id='main'>
//         <div className="chat-container">
//             <div className="chat-header">
//                 <IoMdContact /> {group.name} ({group.members.length} members)
//             </div>
//             <div className="chat-window">
//                 {messages.map((msg, index) => (
//                     <div key={index} className={`chat-message ${msg.sender === 'me' ? 'sent' : 'received'}`}>
//                         <span>{msg.text}</span>
//                         <span className="message-time">{msg.date}</span>
//                     </div>
//                 ))}
//             </div>
//             {showEmojiPicker && (
//                 <EmojiPicker onEmojiClick={handleEmojiSelect} />
//             )}
//             <div className="chat-input">
//                 <Button variant="light" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
//                     <FaSmile />
//                 </Button>
//                 <Form.Control
//                     type="text"
//                     placeholder="Type a message"
//                     value={newMessage}
//                     onChange={(e) => setNewMessage(e.target.value)}
//                 />
//                 <Button variant="primary" onClick={handleSendMessage}>
//                     <AiOutlineSend />
//                 </Button>
//             </div>
//         </div>
//         </div>
//         </>
//     );
// };

// export default GroupChat;




import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { IoMdContact } from 'react-icons/io';
import { FaSmile } from 'react-icons/fa';
import { AiOutlineSend, AiOutlinePaperClip } from 'react-icons/ai';
import EmojiPicker from 'emoji-picker-react';
import './Chat.css'; // Your styles
import Sidebar from './Sidebar';

const GroupChat = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [showFileOptions, setShowFileOptions] = useState(false);
    
    // Separate refs for each file type
    const imageInputRef = useRef(null);
    const videoInputRef = useRef(null);
    const documentInputRef = useRef(null);

    useEffect(() => {
        const fetchGroup = () => {
            const fetchedGroup = {
                id: groupId,
                name:`Group ${groupId}`,
                
                members: [
                    { id: 1, phoneNumber: '+1234567890' },
                    { id: 2, phoneNumber: '+0987654321' },
                ],
            };
            setGroup(fetchedGroup);
        };
        fetchGroup();
    }, [groupId]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            const messageObject = {
                text: newMessage,
                date: new Date().toLocaleString(),
                sender: 'me',
            };
            setMessages([...messages, messageObject]);
            setNewMessage('');
        }
    };

    const handleFileChange = (event, type) => {
        const files = event.target.files;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const messageObject = {
                    text: file.name,
                    date: new Date().toLocaleString(),
                    sender: 'me',
                    type: file.type,
                    url: URL.createObjectURL(file),
                };
                if ((type === 'image' && file.type.startsWith('image/')) ||
                    (type === 'video' && file.type.startsWith('video/')) ||
                    (type === 'document' && file.type === 'application/pdf')) {
                    setMessages((prevMessages) => [...prevMessages, messageObject]);
                }
            }
            event.target.value = '';
        }
        setShowFileOptions(false); // Close dropdown after selecting a file
    };

    const handleEmojiSelect = (emojiData) => {
        setNewMessage(newMessage + emojiData.emoji);
        setShowEmojiPicker(false);
    };

    const toggleFileOptions = () => {
        setShowFileOptions(!showFileOptions);
    };

    if (!group) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Sidebar />
            <div id='main'>
                <div className="chat-container">
                    <div className="chat-header">
                        <IoMdContact /> {group.name}   ({group.members.length} members)
                    </div>
                    <div className="chat-window">
                        {messages.map((msg, index) => (
                            <div key={index} className={`chat-message ${msg.sender === 'me' ? 'sent' : 'received'}`}>
                                <span>{msg.text}</span>
                                {msg.url && (
                                    <div>
                                        {msg.type.startsWith('image/') && (
                                            <img src={msg.url} alt={msg.text} style={{ width: '100px' }} />
                                        )}
                                        {msg.type.startsWith('video/') && (
                                            <video controls style={{ width: '100px' }}>
                                                <source src={msg.url} type={msg.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {msg.type === 'application/pdf' && (
                                            <a href={msg.url} target="_blank" rel="noopener noreferrer">View PDF</a>
                                        )}
                                    </div>
                                )}
                                <span className="message-time">{msg.date}</span>
                            </div>
                        ))}
                    </div>
                    {showEmojiPicker && (
                        <EmojiPicker onEmojiClick={handleEmojiSelect} />
                    )}
                    <div className="chat-input">
                        <Button variant="light" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                            <FaSmile />
                        </Button>
                        <Button
                            variant="light"
                            onClick={toggleFileOptions}
                            style={{ marginLeft: '5px' }}
                        >
                            <AiOutlinePaperClip /> {/* Paperclip icon */}
                        </Button>
                        {showFileOptions && (
                            <div className="file-options">
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, 'image')}
                                        style={{ display: 'none' }}
                                        ref={imageInputRef}
                                    />
                                    <Button style={{alignItems:'center',backgroundColor:'lightblue',color:'black',borderRadius:'150px',height:'30px',width:'40px',fontSize:'12px'}} onClick={() => imageInputRef.current.click()}>
                                        Img
                                    </Button>
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept="video/*"
                                        onChange={(e) => handleFileChange(e, 'video')}
                                        style={{ display: 'none' }}
                                        ref={videoInputRef}
                                    />
                                    <Button style={{alignItems:'center',backgroundColor:'lightblue',color:'black',borderRadius:'150px',height:'30px',width:'40px',fontSize:'12px'}} onClick={() => videoInputRef.current.click()}>
                                        Video
                                    </Button>
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => handleFileChange(e, 'document')}
                                        style={{ display: 'none' }}
                                        ref={documentInputRef}
                                    />
                                    <Button style={{alignItems:'center',backgroundColor:'lightblue',color:'black',borderRadius:'150px',height:'30px',width:'40px',fontSize:'12px'}} onClick={() => documentInputRef.current.click()}>
                                        Doc..
                                    </Button>
                                </div>
                            </div>
                        )}
                        <Form.Control
                            type="text"
                            placeholder="Type a message"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <Button variant="primary" onClick={handleSendMessage}>
                            <AiOutlineSend />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupChat;
