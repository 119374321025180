// import React from "react";
// import Sidebar from "../Chat/Sidebar";



// const ForgotPassword = () => {


//     return(
//         <>
//         <Sidebar/>
//         <div id='main'>
//         <div>
//             ForgotPassword 

//             change your password

//         </div>
//         </div>
        
//         </>
//     );
// };
// export default ForgotPassword;


import React, { useState, useEffect } from "react";
import Sidebar from "../Chat/Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        username: '',
        mobile: '',
        password: '',
        confirmPassword: ''
    });
    const [userList, setUserList] = useState([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            setFormData({
                id: userData.id,
                username: userData.username,
                mobile: userData.mobile || '',
                password: '',
                confirmPassword: ''
            });
            setEditMode(true);
        }
        const usersDB = JSON.parse(localStorage.getItem('usersDB')) || [];
        setUserList(usersDB);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, mobile, password, confirmPassword, id } = formData;

        if (!username || !mobile || !password || !confirmPassword) {
            alert("All fields are required.");
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        const usersDB = JSON.parse(localStorage.getItem('usersDB')) || [];

        if (editMode) {
            // Update existing user via API
            const response = await fetch(`https://demochatapi.emedha.in/api/departuser/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, mobile, password }),
            });

            if (response.ok) {
                alert("User updated successfully!");
            } else {
                alert("Failed to update user. Please try again.");
            }
        } else {
            const existingUser = usersDB.find(user => user.username === username);
            if (existingUser) {
                alert("User already exists!");
                return;
            }
            usersDB.push({ username, mobile, password });
            alert("User created successfully!");
        }

        // Save updated usersDB to localStorage
        localStorage.setItem('usersDB', JSON.stringify(usersDB));

        setFormData({
            id: '',
          
            mobile: '',
            password: '',
            confirmPassword: ''
        });

        setUserList([...usersDB]);
    };


    useEffect(() => {
        const userData = {
            id: localStorage.getItem('userId'), // Assuming you have userId saved
            username: localStorage.getItem('userName'),
            mobile: localStorage.getItem('mobile') || '', // Retrieve mobile number
        };
    
        if (userData.username) {
            setFormData({
                id: userData.id,
                username: userData.username,
                mobile: userData.mobile,
                password: '',
                confirmPassword: ''
            });
            setEditMode(true);
        }
    
        const usersDB = JSON.parse(localStorage.getItem('usersDB')) || [];
        setUserList(usersDB);
    }, []);


    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
    
    const handleItemClick = (action) => {
      console.log(action);
      setDropdownVisible(false);
    };
    
    const handleLogout = () => {
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('rememberMe');
      sessionStorage.removeItem('username');
      localStorage.removeItem('userId');
      navigate('/');
    };
    



    return (
        <>
            <Sidebar /> 
             
                <div  id='main'>
                <div className="container-fluid bg-light p-3 shadow">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h3 className="mt-2"><strong>Change Password</strong></h3>
            </div>
            <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
              <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
              <div style={{ position: 'relative' }}>
                <MdAccountCircle
                  size={30}
                  onClick={toggleDropdown}
                  style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                />
                {dropdownVisible && (
                  <div className="dropdown-menu show" style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                  }}>
                    <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                      <FaUserEdit style={{ marginRight: '8px' }} />
                      Edit Profile
                    </div>
                    <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                      <FaLock style={{ marginRight: '8px' }} />
                      Change Password
                    </div>
                    <div onClick={handleLogout} className="dropdown-item">
                      <FaSignOutAlt style={{ marginRight: '8px' }} />
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
                    
                    
                    
                    <hr />
                    <div className="col-md-6 p-5 offset-md-3 shadow" >         
                        <h5><strong>Update your password</strong></h5>
                        <form onSubmit={handleSubmit} >
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Admin Name:</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    className="form-control"
                                    value={formData.username}
                                    disabled={editMode} 
                                    onChange={handleChange}
                                   
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">Mobile:</label>
                                <input
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    className="form-control"
                                    value={formData.mobile}
                                      placeholder="Enter your Mobile Number"
                                    // disabled={editMode} 
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    value={formData.password}
                                      placeholder="Enter your password"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Re-Enter Password:</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="form-control"
                                      placeholder="Enter your password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button style={{ backgroundColor: 'rgb(125, 133, 195)' }} type="submit" className="btn btn-success btn-sm">Submit</button>
                        </form>                
                    </div>
                </div>
               
        </>
    );
};

export default ForgotPassword;