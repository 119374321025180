// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import { IoMdContact } from 'react-icons/io';
// import { FaSmile } from 'react-icons/fa';
// import EmojiPicker from 'emoji-picker-react'; // Corrected import
// import './Chat.css'; // Create your own CSS file for styling
// import Sidebar from './Sidebar';

// const Chat = () => {
//     const { contactId } = useParams(); // Get contact ID from URL
//     const [contact, setContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false);

//     useEffect(() => {
//         // Mock fetching contact details based on contactId
//         const fetchContact = () => {
//             // Here, replace with your logic to fetch contact details
//             const fetchedContact = { id: contactId, phoneNumber: `+1234567890` }; // Mock data
//             setContact(fetchedContact);
//         };
//         fetchContact();
//     }, [contactId]);

//     const handleSendMessage = () => {
//         if (newMessage.trim()) {
//             const messageObject = {
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//             };
//             setMessages([...messages, messageObject]);
//             setNewMessage('');
//         }
//     };

//     const handleEmojiSelect = (emojiData) => {
//         setNewMessage(newMessage + emojiData.emoji);
//         setShowEmojiPicker(false);
//     };

//     if (!contact) {
//         return <div> go Back Loading...</div>; // Show loading or similar while fetching
//     }

//     return (
//         <>
//         <Sidebar/>
//         <div id='main'>
//         <div className="chat-container">
//             <div className="chat-header">
//                 <IoMdContact /> {contact.phoneNumber}
//                 <Button variant="close" onClick={() => setContact(null)} />
//             </div>
//             <div className="chat-window">
//                 {messages.map((msg, index) => (
//                     <div key={index} className="chat-message">
//                         <span>{msg.text}</span>
//                         <span className="message-time">{msg.date}</span>
//                     </div>
//                 ))}
//             </div>
//             {showEmojiPicker && (
//                 <EmojiPicker onEmojiClick={handleEmojiSelect} />
//             )}
//             <div className="chat-input">
//                 <Button variant="light" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
//                     <FaSmile />
//                 </Button>
//                 <Form.Control
//                     type="text"
//                     placeholder="Type a message"
//                     value={newMessage}
//                     onChange={(e) => setNewMessage(e.target.value)}
//                 />
//                 <Button variant="primary" onClick={handleSendMessage}>
//                     Send
//                 </Button>
//             </div>
//         </div>
//         </div>
//         </>
//     );
// };

// export default Chat;

// import React, { useEffect, useState, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import { IoMdContact } from 'react-icons/io';
// import { FaSmile } from 'react-icons/fa';
// import { AiOutlineSend, AiOutlinePaperClip } from 'react-icons/ai';
// import EmojiPicker from 'emoji-picker-react';
// import './Chat.css'; // Your styles
// import Sidebar from './Sidebar';

// const Chat = () => {
//     const { contactId } = useParams(); // Get contact ID from URL
//     const [contact, setContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const fileInputRef = useRef(null); // Ref for file input

//     useEffect(() => {
//         const fetchContact = () => {
//             const fetchedContact = { id: contactId, phoneNumber: '' }; // Mock data
//             setContact(fetchedContact);
//         };
//         fetchContact();
//     }, [contactId]);

//     // const handleSendMessage = () => {
//     //     if (newMessage.trim() || selectedFile) {
//     //         const messageObject = {
//     //             text: newMessage,
//     //             date: new Date().toLocaleString(),
//     //             file: selectedFile,
//     //         };
//     //         setMessages([...messages, messageObject]);
//     //         setNewMessage('');
//     //         setSelectedFile(null); // Reset file after sending
//     //     }
//     // };
//     const handleSendMessage = () => {
//         if (newMessage.trim() || selectedFile) {
//             const messageObject = {
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//                 file: selectedFile,
//                 sender: 'me', // Mark this message as sent by me
//             };
//             setMessages([...messages, messageObject]);
//             setNewMessage('');
//             setSelectedFile(null); // Reset file after sending
//         }
//     };
    

//     const handleEmojiSelect = (emojiData) => {
//         setNewMessage(newMessage + emojiData.emoji);
//         setShowEmojiPicker(false);
//     };

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             setSelectedFile(file);
//         }
//     };

//     const handleFileButtonClick = () => {
//         fileInputRef.current.click(); // Trigger the file input
//     };

//     // const renderMessage = (msg) => (
//     //     <div className="chat-message">
//     //         <span>{msg.text}</span>
//     //         {msg.file && (
//     //             <a href={URL.createObjectURL(msg.file)} target="_blank" rel="noopener noreferrer">
//     //                 {msg.file.name}
//     //             </a>
//     //         )}
//     //         <span className="message-time">{msg.date}</span>
//     //     </div>
//     // );
//     const renderMessage = (msg) => (
//         <div className={`chat-message ${msg.sender === 'me' ? 'sent' : 'received'}`}>
//             <div className="message-text">
//                 <span>{msg.text}</span>
//                 {msg.file && (
//                     <a href={URL.createObjectURL(msg.file)} target="_blank" rel="noopener noreferrer">
//                         {msg.file.name}
//                     </a>
//                 )}
//             </div>
//             <span className="message-time">{msg.date}</span>
//         </div>
//     );
    

//     if (!contact) {
//         return <div>Loading...</div>; // Show loading while fetching
//     }
    

//     return (
//         <>
//             <Sidebar />
            
//             <div className="chat-container">
//             <div id='main'>
//                 <div className="chat-header">
//                     <IoMdContact /> {contact.phoneNumber}
//                 </div>
//                 <div className="chat-window">
//                     {messages.map((msg, index) => (
//                         <div key={index}>
//                             {renderMessage(msg)}
//                         </div>
//                     ))}
//                 </div>
//                 {showEmojiPicker && (
//                     <EmojiPicker onEmojiClick={handleEmojiSelect} />
//                 )}
//                   </div>
//                 <div id='main'>
//                 <div className="chat-input">
//                     <Button variant="light" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
//                         <FaSmile />
//                     </Button>
//                     <Button
//                         variant="light"
//                         onClick={handleFileButtonClick}
//                         style={{ marginLeft: '5px' }}
//                     >
//                         <AiOutlinePaperClip />
//                     </Button>
//                     <input
//                         type="file"
//                         accept="image/*,video/*,.pdf"
//                         onChange={handleFileChange}
//                         style={{ display: 'none' }}
//                         ref={fileInputRef}
//                     />
//                     <Form.Control
//                         type="text"
//                         placeholder="Type a message"
//                         value={newMessage}
//                         onChange={(e) => setNewMessage(e.target.value)}
//                     />
//                     <Button variant="primary" onClick={handleSendMessage} style={{ marginLeft: '5px' }}>
//                         <AiOutlineSend />
//                     </Button>
//                 </div>
//                 </div>
//             </div>
          
//         </>
//     );
// };

// export default Chat;

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { IoMdContact } from 'react-icons/io';
import { FaSmile } from 'react-icons/fa';
import { AiOutlineSend, AiOutlinePaperClip } from 'react-icons/ai';
import EmojiPicker from 'emoji-picker-react';
import './Chat.css'; // Your styles
import Sidebar from './Sidebar';

const Chat = () => {
    const { contactId } = useParams(); // Get contact ID from URL
    const [contact, setContact] = useState(null);
    const [messages, setMessages] = useState([
        {
            text: "Hello! How can I help you?",
            date: new Date().toLocaleString(),
            sender: 'contact', // Default sender for received message
        },
    ]);
    const [newMessage, setNewMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null); // Ref for file input

    // useEffect(() => {
    //     const fetchContact = () => {
    //         const fetchedContact = { id: contactId, mobail: '' }; // Mock data
    //         setContact(fetchedContact);
    //     };
    //     fetchContact();
    // }, [contactId]);
    useEffect(() => {
        const fetchContact = () => {
            // Mock contact fetching based on contactId (mobail)
            const fetchedContact = { id: contactId, mobail: contactId }; // Use contactId as mobail
            setContact(fetchedContact);
            // Fetch messages for this contact (mock data)
            const fetchedMessages = [
                { text: "Hello! How can I help you?", date: new Date().toLocaleString(), sender: 'contact' },
                { text: "I'm looking for support.", date: new Date().toLocaleString(), sender: 'me' },
            ];
            setMessages(fetchedMessages);
        };
        fetchContact();
    }, [contactId]);

    const handleSendMessage = () => {
        if (newMessage.trim() || selectedFile) {
            const messageObject = {
                text: newMessage,
                date: new Date().toLocaleString(),
                file: selectedFile,
                sender: 'me', // Mark this message as sent by me
            };
            setMessages([...messages, messageObject]);
            setNewMessage('');
            setSelectedFile(null); // Reset file after sending
        }
    };

    const handleEmojiSelect = (emojiData) => {
        setNewMessage(newMessage + emojiData.emoji);
        setShowEmojiPicker(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input
    };

    const renderMessage = (msg) => (
        <div className={`chat-message ${msg.sender === 'me' ? 'sent' : 'received'}`}>
            <div className="message-text">
                <span>{msg.text}</span>
                {msg.file && (
                    <a href={URL.createObjectURL(msg.file)} target="_blank" rel="noopener noreferrer">
                        {msg.file.name}
                    </a>
                )}
            </div>
            <span className="message-time">{msg.date}</span>
        </div>
    );

    if (!contact) {
        return <div>Loading...</div>; // Show loading while fetching
    }

    return (
        <>
            <Sidebar />
            <div id='main'>
            <div className="chat-container">
                <div className="chat-header">
                    <IoMdContact /> {contact.mobail}
                </div>
                <div className="chat-window">
                    {messages.map((msg, index) => (
                        <div key={index}>
                            {renderMessage(msg)}
                        </div>
                    ))}
                </div>
                {showEmojiPicker && (
                    <EmojiPicker onEmojiClick={handleEmojiSelect} />
                )}
                <div className="chat-input">
                    <Button variant="light" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                        <FaSmile />
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleFileButtonClick}
                        style={{ marginLeft: '5px' }}
                    >
                        <AiOutlinePaperClip />
                    </Button>
                    <input
                        type="file"
                        accept="image/*,video/*,.pdf"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Type a message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <Button variant="primary" onClick={handleSendMessage} style={{ marginLeft: '5px' }}>
                        <AiOutlineSend />
                    </Button>
                </div>
            </div>
            </div>
        </>
    );
};

export default Chat;
