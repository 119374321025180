

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { PieChart, Pie, Cell, Tooltip } from 'recharts';
// import Sidebar from '../Chat/Sidebar';
// import './Dashboard.css';
// import { FaLock, FaSignOutAlt, FaUserEdit, FaUsers, FaUsersCog } from 'react-icons/fa';
// import { MdBlock } from "react-icons/md";
// import { RiWifiOffLine } from "react-icons/ri";
// import { IoWifi } from "react-icons/io5";
// import { RiMoneyRupeeCircleFill } from "react-icons/ri";
// import { IoIosNotifications } from "react-icons/io";
// import { MdAccountCircle } from "react-icons/md";
// import { useNavigate } from 'react-router-dom';

// const SuperAdminDashboard = () => {
//   const [pieData, setPieData] = useState([]);
//   const [groupPieData, setGroupPieData] = useState([]);
//   const [totalGroups, setTotalGroups] = useState(0);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [selectedPieData, setSelectedPieData] = useState([]);
//   const [showPieChart, setShowPieChart] = useState(false);
//   const [active, setActive] = useState(0);
//   const [inactiveUsers, setInactiveUsers] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const userResponse = await axios.get('https://demochatapi.emedha.in/api/departuser');
//         const userData = userResponse.data;

//         const formattedUserData = userData.map(user => ({
//           name: user.username,
//           Id: user.id,
//           active: user.active,
//           value: user.mobile.length,
//         }));

//         setPieData(formattedUserData);
//         setTotalUsers(userData.length);
//         const activeUsersCount = userData.filter(user => user.active === 1).length;
//         setActive(activeUsersCount);
//         const inactiveUsersCount = userData.filter(user => user.active === 0).length;
//         setInactiveUsers(inactiveUsersCount);

//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     };

//     const fetchGroups = async () => {
//       try {
//         const groupResponse = await axios.get('https://demochatapi.emedha.in/api/userGroups');
//         const groupData = groupResponse.data.results;

//         const formattedGroupData = groupData.map(group => ({
//           name: group.groupName,
//           groupId: group.groupId,
//           username: group.username, // Ensure username is available
//           value: group.someCount || 1,
//         }));

//         setGroupPieData(formattedGroupData);
//         setTotalGroups(formattedGroupData.length);
//       } catch (error) {
//         console.error('Error fetching group data:', error);
//       }
//     };

//     fetchData();
//     fetchGroups();
//   }, []);

//   const handleIconClick = (type) => {
//     let formattedData = [];
//     if (type === 'active') {
//       formattedData = pieData.filter(user => user.active === 1).map(user => ({
//         name: user.name,
//         value: user.value,
//       }));
//     } else if (type === 'inactive') {
//       formattedData = pieData.filter(user => user.active === 0).map(user => ({
//         name: user.name,
//         value: user.value,
//       }));
//     } else {
//       formattedData = type.map(item => ({
//         name: item.name,
//         username: item.username,
//         value: item.value,
//       }));
//     }
//     setSelectedPieData(formattedData);
//     setShowPieChart(formattedData.length > 0);
//   };
//   const colors = ['#07526f', '#050505', '#36708e', '#4b4d56', '#122e5f', '#002c47'];





//   const [dropdownVisible, setDropdownVisible] = useState(false);

//   const toggleDropdown = () => {
//     setDropdownVisible(!dropdownVisible);
//   };

//   const handleItemClick = (action) => {
//     console.log(action);
//     setDropdownVisible(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('userData');
//     localStorage.removeItem('token');
//     localStorage.removeItem('loginEmail');
//     localStorage.removeItem('rememberMe');
//     sessionStorage.removeItem('username');
//     localStorage.removeItem('userId');
//     navigate('/');
//   };


//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <div className="container-fluid">
//           <div className="row justify-content-between align-items-center">
//             <div className="col-auto">
//               <h3 className="mt-2"><strong>SUPER ADMIN DASHBOARD</strong></h3>
//             </div>
//             <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//               <IoIosNotifications size={30} />
//               <div style={{ position: 'relative' }}>
//                 <MdAccountCircle
//                   size={30}
//                   onClick={toggleDropdown}
//                   style={{ cursor: 'pointer' }}
//                 />
//                 {dropdownVisible && (
//                   <div className="dropdown-menu show" style={{
//                     position: 'absolute',
//                     right: 0,
//                     backgroundColor: 'white',
//                     border: '1px solid #ccc',
//                     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     zIndex: 1,
//                   }}>
//                     <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
//                       <FaUserEdit style={{ marginRight: '8px' }} />
//                       Edit Profile
//                     </div>
//                     <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
//                       <FaLock style={{ marginRight: '8px' }} />
//                       Change Password
//                     </div>
//                     <div onClick={handleLogout} className="dropdown-item">
//                       <FaSignOutAlt style={{ marginRight: '8px' }} />
//                       Logout
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         <hr />
//         <div className="dashboard" >
//           <main className="main-content d-flex flex-column flex-md-row justify-content-around">
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: '#ffffff', borderRadius: '20px' }} onClick={() => handleIconClick(pieData)}>
//               <FaUsersCog size={50} style={{ cursor: 'pointer' }} />
//               <h2 >{totalUsers}</h2>
//               <p>Total Admins</p>
//             </div>
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: 'white', borderRadius: '20px' }} onClick={() => handleIconClick(groupPieData)}>
//               <FaUsers size={50} style={{ cursor: 'pointer' }} />
//               <h2>{totalGroups}</h2>
//               <p>Total Groups</p>
//             </div>
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: 'white', borderRadius: '20px' }} onClick={() => handleIconClick('active')}>
//               <IoWifi size={50} style={{ cursor: 'pointer' }} />
//               <h2>{active}</h2>
//               <p>Active Admins</p>
//             </div>
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: 'white', borderRadius: '20px' }} onClick={() => handleIconClick('inactive')}>
//               <RiWifiOffLine size={50} style={{ cursor: 'pointer' }} />
//               <h2>{inactiveUsers}</h2>
//               <p>Inactive Admins</p>
//             </div>
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: 'white', borderRadius: '20px' }} onClick={() => handleIconClick([])}>
//               <MdBlock size={50} style={{ cursor: 'pointer' }} />
//               <h2>0</h2>
//               <p>Blocked</p>
//             </div>
//             <div className="card mb-3" style={{ backgroundColor: '#002c47', color: '#ffffff', borderRadius: '20px' }} onClick={() => handleIconClick([])}>
//               <RiMoneyRupeeCircleFill size={50} style={{ cursor: 'pointer' }} />
//               <h2>0</h2>
//               <p>Credits</p>
//             </div>
//           </main>

//           {showPieChart && (
//             <div style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
//               <PieChart width={400} height={400} style={{ marginRight: '20px' }}>
//                 <Tooltip />
//                 <Pie
//                   data={selectedPieData}
//                   cx={200}
//                   cy={200}
//                   outerRadius={80}
//                   fill="#8884d8"
//                   dataKey="value"
//                   nameKey="name"
//                 >
//                   {selectedPieData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
//                   ))}
//                 </Pie>
//               </PieChart>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default SuperAdminDashboard;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import Sidebar from '../Chat/Sidebar';
import './Dashboard.css';
import { FaLock, FaSignOutAlt, FaUserEdit, FaUsers, FaUsersCog } from 'react-icons/fa';
import { MdBlock } from "react-icons/md";
import { RiWifiOffLine } from "react-icons/ri";
import { IoWifi } from "react-icons/io5";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { IoIosNotifications } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
// import 'tailwindcss/tailwind.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import {  FaShoppingCart, FaChartLine } from 'react-icons/fa';


const SuperAdminDashboard = () => {
  const [pieData, setPieData] = useState([]);
  const [groupPieData, setGroupPieData] = useState([]);
  const [totalGroups, setTotalGroups] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedPieData, setSelectedPieData] = useState([]);
  const [showPieChart, setShowPieChart] = useState(false);
  const [active, setActive] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [creditsData, setCreditsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get('https://demochatapi.emedha.in/api/departuser');
        const userData = userResponse.data;

        const formattedUserData = userData.map(user => ({
          name: user.username,
          Id: user.id,
          active: user.active,
          value: user.mobile.length,
          credits: user.credits || 0, // assuming credits is part of the user data
        }));

        setPieData(formattedUserData);
        setTotalUsers(userData.length);
        setActive(formattedUserData.filter(user => user.active === 1).length);
        setInactiveUsers(formattedUserData.filter(user => user.active === 0).length);

        // Setting creditsData for pie chart
        const creditsCount = formattedUserData.filter(user => user.credits > 0).map(user => ({
          username: user.name,
          value: user.credits,
        }));
        setCreditsData(creditsCount);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const groupResponse = await axios.get('https://demochatapi.emedha.in/api/userGroups');
        const groupData = groupResponse.data.results;

        const formattedGroupData = groupData.map(group => ({
          name: group.groupName,
          groupId: group.groupId,
          username: group.username,
          value: group.someCount || 1,
        }));

        setGroupPieData(formattedGroupData);
        setTotalGroups(formattedGroupData.length);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    fetchData();
    fetchGroups();
  }, []);

  const handleIconClick = (type) => {
    let formattedData = [];
    if (type === 'active') {
      formattedData = pieData.filter(user => user.active === 1).map(user => ({
        name: user.name,
        value: user.value,
      }));
    } else if (type === 'inactive') {
      formattedData = pieData.filter(user => user.active === 0).map(user => ({
        name: user.name,
        value: user.value,
      }));
    } else if (type === 'credits') {
      formattedData = creditsData.map(credit => ({
        name: credit.username,
        value: credit.value,
      }));
    } else {
      formattedData = type.map(item => ({
        name: item.name,
        username: item.username,
        value: item.value,
      }));
    }
    setSelectedPieData(formattedData);
    setShowPieChart(formattedData.length > 0);
  };

  const colors = ['#07526f', '#050505', '#36708e', '#4b4d56', '#122e5f', '#002c47'];
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('username');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const handleItemClick = (action) => {
    console.log(action); // Handle item click actions here
    setDropdownVisible(false);
  };
  const unusedCredits = creditsData.reduce((sum, user) => {
    return sum + (user.value > 0 ? user.value : 0);
  }, 0);

  return (
    <>
      <Sidebar />
      <div id='main'>
        
      <div className="container-fluid bg-light p-3 shadow">
  <div className="row justify-content-between align-items-center">
    <div className="col-auto">
      <h3 className="mt-2"><strong>Super Admin Dashboard</strong></h3>
    </div>
    <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
      <IoIosNotifications size={30} style={{color:'rgb(125, 133, 195)'}}  />
      <div style={{ position: 'relative' }}>
        <MdAccountCircle
          size={30}
          onClick={toggleDropdown}
          style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
        />
        {dropdownVisible && (
          <div className="dropdown-menu show" style={{
            position: 'absolute',
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}>
            <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
              <FaUserEdit style={{ marginRight: '8px' }} />
              Edit Profile
            </div>
            <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
              <FaLock style={{ marginRight: '8px' }} />
              Change Password
            </div>
            <div onClick={handleLogout} className="dropdown-item">
              <FaSignOutAlt style={{ marginRight: '8px' }} />
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>


        <hr />

        <div className="dashboard">


      



          <main className="main-content d-flex flex-column flex-md-row justify-content-around">
          {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div className="card purple" style={{ width: '120px',height:'120px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick(pieData)}>
     
       
        <h3>   <FaUsersCog /> {totalUsers}</h3>
        <p>Total Admins</p>
      </div>

      <div className="card yellow" style={{ width: '200px', textAlign: 'center', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
        <FaShoppingCart size={50} style={{ cursor: 'pointer', marginBottom: '15px' }} />
        <h2>12.2K</h2>
        <p>Total Purchases</p>
      </div>

      <div className="card teal" style={{ width: '200px', textAlign: 'center', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
        <FaChartLine size={50} style={{ cursor: 'pointer', marginBottom: '15px' }} />
        <h2>50.5K</h2>
        <p>Total Sales</p>
      </div>
    </div> */}
             <div className="card  mb-3" style={{ borderLeft: '6px solid blue',width: '150px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick(pieData)}>
              
              <FaUsersCog size={35} style={{ backgroundColor:'blue',color:'white',borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer'}} />
              
         
              <p style={{color:'blue',fontSize:'30px'}}>{totalUsers}</p>
              <p><b>Total Admins</b></p>
            </div>
            <div className="card  mb-3" style={{borderLeft: '6px solid orange', width: '150px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick(groupPieData)}>
              <FaUsers size={35} style={{backgroundColor:'orange',color:'white',borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <p style={{color:'orange',fontSize:'30px'}}>{totalGroups}</p>
              <p><b>Total Groups</b></p>
            </div>
            <div className="card  mb-3"style={{borderLeft: '6px solid green', width: '150px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick('active')}>
              <IoWifi size={35} style={{backgroundColor:'green',color:'white',borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <p style={{color:'green',fontSize:'30px'}}>{active}</p>
              <p><b>Active Admins</b></p>
            </div>
            <div className="card  mb-3" style={{borderLeft: '6px solid teal', width: '150px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick('inactive')}>
              <RiWifiOffLine size={35} style={{backgroundColor:'teal',color:'white',borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <h2>{inactiveUsers}</h2>
              <p><b>Inactive Admins</b></p>
            </div>
            
            <div className="card  mb-3" style={{borderLeft: '6px solid black', width: '150px', textAlign: 'center',fontSize:'15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} onClick={() => handleIconClick('credits')}>
              <RiMoneyRupeeCircleFill size={35} style={{borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <p  style={{color:'teal',fontSize:'30px'}} >{creditsData.reduce((sum, user) => sum + user.value, 0) || 0}</p>
              <p><b>Total Credits</b></p>
            </div>
            {/* <div className="card mb-3" style={{ backgroundColor: '#002c47', color: 'white', borderRadius: '20px' }} onClick={() => handleIconClick([])}>
              <MdBlock size={50} style={{ cursor: 'pointer' }} />
              <h2>0</h2>
              <p>UnUsed Credits</p>
            </div>
            <div className="card mb-3" style={{ backgroundColor: '#002c47', color: '#ffffff', borderRadius: '20px' }} onClick={() => handleIconClick('credits')}>
              <RiMoneyRupeeCircleFill size={50} style={{ cursor: 'pointer' }} />
              <h2>{unusedCredits}</h2>
              <p>Used Credits</p>
            </div> */}
          </main>

          {showPieChart && (
            <div style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
              <PieChart width={400} height={400} style={{border: '2px solid green'  }}>
                <Tooltip />
                <Pie
                  data={selectedPieData}
                  cx={200}
                  cy={200}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  nameKey="name"
                >
                  {selectedPieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashboard;

