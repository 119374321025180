
// Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import { toast } from 'react-toastify';
const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobile: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const navigate = useNavigate();

  const handlePasswordToggle = () => setShowPassword(!showPassword);
  const handlePasswordToggle1 = () => setShowPassword1(!showPassword1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const { username, email, password, confirmPassword, mobile } = formData;

  //   if (password !== confirmPassword) {
  //     toast.error("Passwords don't match!");
  //     return;
  //   }

  //   const newUser = {
  //     username,
  //     email,
  //     mobile,
  //     password
  //   };

  //   localStorage.setItem('userData', JSON.stringify(newUser));
  //   //alert("User registered successfully!");
  //   toast.success("User registered successfully!"); // Show success message
  //   navigate('/'); // Navigate to login page after successful signup
  // };
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, email, password, confirmPassword, mobile } = formData;

    if (password !== confirmPassword) {
        toast.error("Passwords don't match!");
        return;
    }

    const newUser = {
        username,
        email,
        mobile,
        password,
        confirmPassword // Include confirmPassword for validation but not in the request
    };

    try {
        const response = await fetch(' https://demochatapi.emedha.in/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newUser),
        });

        const data = await response.json();

        if (response.ok) {
            toast.success(data.message); // Show success message
            navigate('/otp-form'); // Navigate to login page after successful signup
        } else {
            toast.error(data.error); // Show error message
        }
    } catch (error) {
        console.error('Signup error:', error);
        toast.error('Signup failed. Please try again.');
    }
};

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h3>Create an Account</h3>
          <p className="subheading">Please fill in the details to sign up</p>
        </div>
        <div className="auth-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Full Name</label>
              <input
                className="form-control"
                id="username"
                name="username"
                type="text"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Enter your full name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input
                className="form-control"
                id="mobile"
                name="mobile"
                type="text"
                value={formData.mobile}
                onChange={handleInputChange}
                placeholder="Enter your mobile number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail">Email Address</label>
              <input
                className="form-control"
                id="inputEmail"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword">Password</label>
              <input
                className="form-control"
                id="inputPassword"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter your password"
                required
              />
              {/* <a type="button" onClick={handlePasswordToggle}>
                {showPassword ? <ImEye /> : <PiEyeClosedBold />}
              </a> */}
            </div>
            <div className="form-group">
              <label htmlFor="inputConfirmPassword">Confirm Password</label>
              <input
                className="form-control"
                id="inputConfirmPassword"
                name="confirmPassword"
                type={showPassword1 ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm your password"
                required
              />
              {/* <button type="button" onClick={handlePasswordToggle1}>
                {showPassword1 ? <ImEye /> : <PiEyeClosedBold />}
              </button> */}
            </div>
            <div className="form-group">
              <button className="btn-auth">Sign Up</button>
            </div>
            <div className="auth-footer">
              <p className="sign-up-prompt">
                Already have an account? <a href="/">Login</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
